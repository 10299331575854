function slickImoveis() {
	var carousel = $('#carousel-imoveis');
	var items    = carousel.find('.slick-slide:not(.slick-cloned) a');

	carousel.after('<div id="imoveisLightboxThumbs"></div>');

	var thumbsContainer = $('#imoveisLightboxThumbs');

	items.each(function() {
		var $this    = $(this);
		var href     = $this.attr('href');
		var clone    = document.createElement('a');
		var $clone   = $(clone);
		var lightbox = 'imovel-thumbnail';

		$this.removeAttr('data-lightbox');

		$clone
			.attr('href', href)
			.attr('data-lightbox', lightbox);

		thumbsContainer.append(clone);

		$this.click(function(e) {
			e.preventDefault();

			$clone.trigger('click');
		});
	});
};
