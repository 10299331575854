$('.input-slider').each(function(){
  var min   = $(this).data('min');
  var max   = $(this).data('max');
  var minOut  = $(this).data('out-min');
  var maxOut  = $(this).data('out-max');

  $(this).slider({
    values: [min,max],
    min: min,
    max: max,
    range: true,
    slide : function(event, ui){
      var min = new Number(ui.values[0]);
      var max = new Number(ui.values[1]);

      $(minOut).val(min.toLocaleString())
      $(maxOut).val(max.toLocaleString())
    }
  })
  
});