var hasSlick = (function() {
	var slickSlider = $('.slick-slider');

	if(slickSlider.length > 0) {
		var link 	= document.createElement('link');
		var script 	= document.createElement('script');

		link.rel 	= 'stylesheet';
		link.href 	= 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css';

		document.head.appendChild(link);

		script.src = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js';

		document.body.appendChild(script);

		script.addEventListener('load', function() {
			$.fn.slicker = function() {
				var options = {};

				return this.each(function() {
					var self 		= $(this);
					var midias 		= ['xs','sm', 'md', 'lg', 'xl'];
					var prev 		= `<button type="button" class="slick-prev" aria-label="Anterior"><span class="sr-only">Previous</span><i class="${self.data('prev') || 'fas fa-chevron-left'}"></i></button>`;
					var next 		= `<button type="button" class="slick-next" aria-label="Próximo"><span class="sr-only">Next</span><i class="${self.data('next') || 'fas fa-chevron-right'}"></i></button>`;

					var data = {
						dots       	   	: dataMaker('dots', false),
						arrows         	: dataMaker('nav', false),
						rows         	: dataMaker('rows', 1),
						slidesToShow   	: dataMaker(),
						adaptiveHeight 	: dataMaker('auto-height', false),
						variableWidth 	: dataMaker('auto-width', false),
						centerMode 		: dataMaker('center', false),
						centerPadding 	: dataMaker('center-padding', false),
						vertical 		: dataMaker('vertical', false),
						verticalSwiping : dataMaker('vertical', false)
					}

					data.slideBy = slideBy(data);


					var config = {
						infinite 		: true,
						autoplay 		: self.data('autoplay') || false,
						speed    		: 300,
						prevArrow 		: prev,
						nextArrow 		: next,
						autoplaySpeed   : self.data('interval') || 5000,
						lazyLoad 		: 'ondemand',
						responsive 		: [
							{
								breakpoint : 1100,
								settings   : responsiveMaker('xl')
							},
							{
								breakpoint : 992,
								settings   : responsiveMaker('lg')
							},
							{
								breakpoint : 768,
								settings   : responsiveMaker('md')
							},
							{
								breakpoint : 600,
								settings   : responsiveMaker('sm')
							},
							{
								breakpoint : 400,
								settings   : responsiveMaker('xs')
							}
						]
					};

					for(let prop in data) {
						config[prop] = data[prop].xl;
					}

					self.data('slicker.data', data);
					self.data('slicker.config', config);

					$(this).slick(config);

					function slideBy(data){
						var midia 	= data.slidesToShow;
						var fbk 	= self.data('slide-by');
						var config = {};

						for(var i=0; i<midias.length; i++) {
							var m = midias[i];

							config[m] = verify(('slide-by-'+m), m,fbk);
						}

						function verify(attr, m, fbk) {
							return self.data(attr) === 'page' ? midia[m] : fbk;
						}

						return config;
					}

					function dataMaker(attr,fbk){
						var data     = {};

						if(attr) {

							var fallback = self.data(attr) || fbk;

							data.xs = self.data(attr+'-xs') || fallback;
							data.sm = self.data(attr+'-sm') || data.xs;
							data.md = self.data(attr+'-md') || data.sm;
							data.lg = self.data(attr+'-lg') || data.md;
							data.xl = self.data(attr+'-xl') || data.lg;
						}else {
							var fallback = 1;

							data.xs = self.data('xs') || fallback;
							data.sm = self.data('sm') || data.xs;
							data.md = self.data('md') || data.sm;
							data.lg = self.data('lg') || data.md;
							data.xl = self.data('xl') || data.lg;
						}

						return data;
					}

					function responsiveMaker(midia){
						return {
							dots 			: data.dots[midia],
							arrows 			: data.arrows[midia],
							rows 			: data.rows[midia],
							slidesToShow 	: data.slidesToShow[midia],
							adaptiveHeight 	: data.adaptiveHeight[midia],
							variableWidth 	: data.variableWidth[midia],
							centerMode 		: data.centerMode[midia],
							centerPadding 	: data.centerPadding[midia],
							vertical 		: data.vertical[midia],
							verticalSwiping : data.verticalSwiping[midia],
							slideBy 		: data.slideBy[midia],
						}
					}
				});
			};

			$('.slick-slider').slicker();

			slickImoveis();
		});
	}
})();
